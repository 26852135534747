import "./App.css";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { createTransferInstruction, getAssociatedTokenAddress } from "@solana/spl-token";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { useEffect, useState } from "react";
import TimeAgo from "react-timeago";
import toast, { Toaster } from "react-hot-toast";
import Rock from "./assets/therock.png";
import Paper from "./assets/paperstack.png";
import Sci from "./assets/scissor.png";
import MeteorShower from "./Meteor";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  const { publicKey, sendTransaction } = useWallet();

  const rpc = `https://mainnet.helius-rpc.com/?api-key=${process.env.REACT_APP_RPC_KEY}`;

  const connection = new Connection(rpc, "processed");

  const SYS_WALLET = process.env.REACT_APP_SYS_WALLET;
  const SOL_AMOUNT = process.env.REACT_APP_SOL_FEE;
  const TOKEN_CA = process.env.REACT_APP_TOKEN_CA;
  const AMOUNT = process.env.REACT_APP_PLAY_AMOUNT;
  const DECIMALS = process.env.REACT_APP_DECIMALS;
  const FEE_PC = process.env.REACT_APP_FEE_PC;

  const transfer = async (option) => {
    const toastId = toast.loading("ROCK, PAPER, SCISSORS...", {
      style: {
        background: "#222",
        color: "#fff",
      },
    });
    try {
      if (!publicKey) {
        toast.error("Wallet not connected", {
          id: toastId,
          duration: 8000,
        });
        return;
      }

      let TOKEN_ATA = await getAssociatedTokenAddress(
        new PublicKey(TOKEN_CA),
        new PublicKey(SYS_WALLET)
      );

      let DEST_ATA = await getAssociatedTokenAddress(
        new PublicKey(TOKEN_CA),
        new PublicKey(publicKey)
      );

      const tx = new Transaction();
      tx.add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(SYS_WALLET),
          lamports: SOL_AMOUNT * LAMPORTS_PER_SOL,
        })
      );
      tx.add(
        createTransferInstruction(DEST_ATA, TOKEN_ATA, publicKey, AMOUNT * Math.pow(10, DECIMALS))
      );
      tx.add(
        new TransactionInstruction({
          keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
          data: Buffer.from(option, "utf-8"),
          programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
        })
      );

      try {
        const txid = await sendTransaction(tx, connection);
        try {
          const blockhash = await connection.getLatestBlockhash();
          const confirm = await connection.confirmTransaction(
            {
              blockhash: blockhash.blockhash,
              lastValidBlockHeight: blockhash.lastValidBlockHeight,
              signature: txid,
            },
            "confirmed"
          );

          if (confirm.value.err === null) {
            console.log(txid);
            toast.success(
              <span>
                {option.toUpperCase()}!{" "}
                <a
                  href={`https://solscan.io/tx/${txid}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: "10px" }}
                >
                  VIEW TX
                </a>
              </span>,
              {
                id: toastId,
                duration: 8000,
              }
            );
          } else {
            console.log(confirm.value.err);
            toast.error(
              <span>
                FAILED!{" "}
                <a
                  href={`https://solscan.io/tx/${txid}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: "10px" }}
                >
                  VIEW TX
                </a>
              </span>,
              {
                id: toastId,
                duration: 8000,
              }
            );
          }
        } catch (error) {
          console.log(error);
          toast.error(
            <span>
              FAILED!{" "}
              <a
                href={`https://solscan.io/tx/${txid}`}
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: "10px" }}
              >
                VIEW TX
              </a>
            </span>,
            {
              id: toastId,
              duration: 8000,
            }
          );
        }
      } catch {
        toast.error(
          <span>FAILED!</span>,
          {
            id: toastId,
            duration: 8000,
          },
          {
            style: {
              border: "1px solid white",
              background: "black",
              color: "#fff",
            },
          }
        );
      }
    } catch {
      toast.error(
        <span>FAILED!</span>,
        {
          id: toastId,
          duration: 8000,
        },
        {
          style: {
            border: "1px solid white",
            background: "black",
            color: "#fff",
          },
        }
      );
    }
  };

  const [pending, setPending] = useState(false);
  const [games, setGames] = useState([]);
  const [myGames, setMyGames] = useState(0);
  const [myWins, setMyWins] = useState(0);
  const [myLosses, setMyLosses] = useState(0);
  const [myDraws, setMyDraws] = useState(0);
  const [rockCount, setRockCount] = useState(0);
  const [paperCount, setPaperCount] = useState(0);
  const [sciCount, setSciCount] = useState(0);

  useEffect(() => {
    async function getGames() {
      try {
        const response = await fetch("/api/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const gamesData = await response.json();
        if (!gamesData[gamesData.length - 1]?.hasOwnProperty("user_two") && gamesData.length > 0) {
          setPending(true);
        }
        console.log(gamesData);
        setGames(gamesData);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    }
    getGames();
  }, []);

  useEffect(() => {
    let eventSource = new EventSource("/api/events");

    eventSource.onmessage = function (event) {
      const message = JSON.parse(event.data);
      console.log(message);
      if (message.type === "complete") {
        setGames((prevMessages) => {
          return [...prevMessages, message];
        });
        setPending(false);
      } else if (message.type === "newGame") {
        setPending(true);
      }
    };

    eventSource.onerror = function (err) {
      console.error("closed");
      eventSource.close();
      // Attempt to reconnect after a delay
      setTimeout(() => {
        eventSource = new EventSource("/api/events");
      }, 5000);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    if (games.length > 0) {
      let searchId;
      if (publicKey) {
        // ID to search for
        searchId = publicKey.toString();
      }
      // Counter to keep track of occurrences
      let count = 0;
      let wins = 0;
      let losses = 0;
      let draws = 0;
      let rc = 0;
      let pc = 0;
      let sc = 0;
      // Loop through each object in the array
      for (const obj of games) {
        if (obj.user_one === searchId) {
          if (obj.winner === "one") {
            wins++;
          } else if (obj.winner === "two") {
            losses++;
          } else if (obj.winner === "draw") {
            draws++;
          }
          count++;
        }

        if (obj.user_two === searchId) {
          if (obj.winner === "two") {
            wins++;
          } else if (obj.winner === "one") {
            losses++;
          } else if (obj.winner === "draw") {
            draws++;
          }
          count++;
        }

        if (obj.option_one === "rock") {
          rc++; // Increment the counter if the ID is found
        }
        if (obj.option_two === "rock") {
          rc++; // Increment the counter if the ID is found
        }
        if (obj.option_one === "paper") {
          pc++; // Increment the counter if the ID is found
        }
        if (obj.option_two === "paper") {
          pc++; // Increment the counter if the ID is found
        }
        if (obj.option_one === "scissor") {
          sc++; // Increment the counter if the ID is found
        }
        if (obj.option_two === "scissor") {
          sc++; // Increment the counter if the ID is found
        }
      }
      setMyGames(count);
      setMyWins(wins);
      setMyLosses(losses);
      setMyDraws(draws);
      setRockCount(rc);
      setPaperCount(pc);
      setSciCount(sc);
    }
  }, [games, publicKey]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingTop: 30,
      }}
    >
      <MeteorShower />
      <div
        className="main"
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between", width: "90%", margin: "auto" }}
        >
          <div className="glass" style={{ display: "flex", gap: 5 }}>
            <div style={{ color: "gray" }}>ROCK</div>
            <div>{rockCount * AMOUNT}</div>
          </div>
          <div className="glass" style={{ display: "flex", gap: 5 }}>
            <div style={{ color: "gray" }}>PAPER</div>
            <div>{paperCount * AMOUNT}</div>
          </div>
          <div className="glass" style={{ display: "flex", gap: 5 }}>
            <div style={{ color: "gray" }}>SCISSOR</div>
            <div>{sciCount * AMOUNT}</div>
          </div>
        </div>
        <div className="glass glitch-wrapper" style={{ margin: "auto" }}>
          <div className="glitched-text" data-text="RPS">
            RPS
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            margin: "auto",
            alignItems: "center",
          }}
        >
          <div className="glass" style={{ display: "flex", gap: 5 }}>
            <div style={{ color: "gray" }}>GAMES</div>
            <div>{games.length}</div>
          </div>
          <div className="glass">
            <div style={{ color: "gray", fontSize: 25 }}>RECORD</div>
            <div style={{ fontSize: 35 }}>
              <span style={{ color: "green" }}>{myWins}</span> -{" "}
              <span style={{ color: "red" }}>{myLosses}</span> -{" "}
              <span style={{ color: "orange" }}>{myDraws}</span>
            </div>
          </div>
          <div className="glass" style={{ display: "flex", gap: 5 }}>
            <div style={{ color: "gray" }}>PLAYED</div>
            <div>{myGames}</div>
          </div>
        </div>
        <div style={{ padding: 15 }}>
          <WalletMultiButton />
        </div>
        <div className="glass" style={{ padding: 20, fontSize: 30, margin: "auto" }}>
          <span style={{ color: "#90a4ae" }}>INSERT</span>{" "}
          <span style={{ color: "#ba68c8" }}>{AMOUNT} $RPS</span>
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: 15, padding: 10 }}>
          <div
            className="glass"
            style={{
              padding: 20,
              cursor: "pointer",
              borderRadius: 50,
            }}
            onClick={() => transfer("rock")}
          >
            <img src={Rock} alt="rock" width={60} height={60} />
            <div>ROCK</div>
          </div>
          <div
            className="glass"
            style={{
              padding: 20,
              cursor: "pointer",
              borderRadius: 50,
            }}
            onClick={() => transfer("paper")}
          >
            <img src={Paper} alt="paper" width={60} height={60} />
            <div>PAPER</div>
          </div>
          <div
            className="glass"
            style={{
              padding: 20,
              cursor: "pointer",
              borderRadius: 50,
            }}
            onClick={() => transfer("scissor")}
          >
            <img src={Sci} alt="scissors" width={60} height={60} />
            <div>SCISSOR</div>
          </div>
        </div>
        <div
          style={{
            margin: "auto", // Center horizontally
            marginTop: 0,
            marginBottom: 0,
            padding: 20,
          }}
          className="glass"
        >
          <div style={{ padding: 8, display: "flex" }}>
            <div style={{ justifySelf: "center", margin: "auto", fontSize: 25, fontWeight: 600 }}>
              GAMES
            </div>
          </div>
          <div style={{ maxHeight: 400, overflowY: "auto" }}>
            {pending && (
              <div
                style={{
                  padding: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                className="shake-animation"
              >
                <div className="flash">PENDING</div>
              </div>
            )}
            {games
              ?.slice()
              .reverse()
              .map((g, index) => (
                <div key={index}>
                  {g.user_two ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        gap: 15,
                        padding: 8,
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        backgroundColor:
                          g.user_one === publicKey?.toString()
                            ? "rgba(255, 255, 255, 0.1)"
                            : g.user_two === publicKey?.toString()
                            ? "rgba(255, 255, 255, 0.1)"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 15,
                          color:
                            g.winner === "one" ? "green" : g.winner === "draw" ? "orange" : "red",
                        }}
                      >
                        <div>
                          <a
                            href={`https://solscan.io/account/${g.user_one}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: "none",
                              color:
                                g.winner === "one"
                                  ? "green"
                                  : g.winner === "draw"
                                  ? "orange"
                                  : "red",
                            }}
                          >
                            {g.user_one.substring(0, 4)}
                          </a>
                        </div>
                        <div>
                          <a
                            href={`https://solscan.io/tx/${g.tx_one}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {g.option_one === "rock" ? (
                              <img src={Rock} alt="rock" width={20} height={20} />
                            ) : g.option_one === "paper" ? (
                              <img src={Paper} alt="paper" width={20} height={20} />
                            ) : (
                              <img src={Sci} alt="scissors" width={20} height={20} />
                            )}
                          </a>
                        </div>
                      </div>
                      <div style={{ color: "gray" }}>vs</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 15,
                          color:
                            g.winner === "two" ? "green" : g.winner === "draw" ? "orange" : "red",
                        }}
                      >
                        <div>
                          <a
                            href={`https://solscan.io/tx/${g.tx_two}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {g.option_two === "rock" ? (
                              <img src={Rock} alt="rock" width={20} height={20} />
                            ) : g.option_two === "paper" ? (
                              <img src={Paper} alt="paper" width={20} height={20} />
                            ) : (
                              <img src={Sci} alt="scissors" width={20} height={20} />
                            )}
                          </a>
                        </div>
                        <div>
                          <a
                            href={`https://solscan.io/account/${g.user_two}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: "none",
                              color:
                                g.winner === "two"
                                  ? "green"
                                  : g.winner === "draw"
                                  ? "orange"
                                  : "red",
                            }}
                          >
                            {g.user_two.substring(0, 4)}
                          </a>
                        </div>
                      </div>
                      <div>
                        <a
                          href={`https://solscan.io/tx/${g.pay_tx}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                          className="hoverWhite"
                        >
                          <TimeAgo date={g.date} />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="glass" style={{ margin: 20 }}>
          <div style={{ fontSize: 25, fontWeight: 600, padding: 20 }}>HOW TO PLAY</div>
          <div style={{ fontSize: 20, gap: 5, display: "flex", flexDirection: "column" }}>
            <div>1. Pick your poison: ROCK, PAPER or SCISSOR</div>
            <div>
              2. Pay {AMOUNT} $RPS + {SOL_AMOUNT} SOL fee
            </div>
            <div>3. Wait for a second player to play</div>
            <div>4. Classic Rock, Paper, Scissor rules:</div>
            <div style={{ fontSize: 16 }}>a. Paper beats rock</div>
            <div style={{ fontSize: 16 }}>b. Rock beats scissor</div>
            <div style={{ fontSize: 16 }}>c. Scissor beats paper</div>
            <div>5. Winner gets their tokens back plus opponents tokens minus {FEE_PC * 100}%</div>
            <div>6. If it's a draw everyone gets their tokens back minus {FEE_PC * 100}%</div>
            <div>7. The full {FEE_PC * 100}% fee will be burned</div>
            <div>8. The {SOL_AMOUNT} SOL fee is to cover gas and other fees</div>
          </div>
        </div>
        <div className="glass" style={{ margin: 20 }}>
          <div style={{ fontSize: 25, fontWeight: 600, padding: 20 }}>TOKEN</div>

          <div style={{ padding: 5 }}>
            <div style={{ color: "gray" }}>RPS CA</div>
            <div style={{ wordWrap: "break-word" }}>{TOKEN_CA}</div>
          </div>
        </div>
        <div className="glass" style={{ margin: 20 }}>
          <div
            style={{
              padding: 20,
              display: "flex",
              gap: 20,
              justifyContent: "center",
              fontSize: 30,
            }}
          >
            <div>
              <a
                href={`https://twitter.com/rpsdotwtf`}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                TWITTER
              </a>
            </div>
            <div>
              <a
                href={`https://t.me/rpsdotwtf`}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                TELEGRAM
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Toaster position="bottom-right" />
      </div>
    </div>
  );
}

export default App;
