// src/MeteorShower.js
import React, { useEffect } from "react";

const MeteorShower = () => {
  useEffect(() => {
    createMeteors();
  }, []);

  const createMeteors = () => {
    const meteorShower = document.querySelector(".meteor-shower");
    const shapes = ["triangle", "square", "circle"];

    for (let i = 0; i < 50; i++) {
      const meteor = document.createElement("div");
      meteor.classList.add("meteor");

      // Randomly assign a shape class
      const shape = shapes[Math.floor(Math.random() * shapes.length)];
      meteor.classList.add(shape);

      meteor.style.left = `${Math.random() * 100}vw`;
      meteor.style.top = `${Math.random() * 100}vh`;
      meteor.style.animationDelay = `${Math.random() * 50}s`;

      meteorShower.appendChild(meteor);
    }
    console.log("Meteors created:", meteorShower.children.length);
  };

  return <div className="meteor-shower"></div>;
};

export default MeteorShower;
